import { HeadersContent } from 'groq/global-types';
import { ManageHeadingTag } from 'ui/content/ContentHeading';
import { ComponentWithFilters as GqlComponentWithFilters } from '__generated__/graphql';

const headerKeys: Array<keyof HeadersContent | 'h4'> = [
  'h1Heading',
  'h2Headings',
  'h3Headings',
  'h4',
];
const PRIMARY_HEADINGS = 'h1, h2, h3';

const WHITELIST_COMPONENTS = [
  'FeaturesGrid',
  'FeaturesGridRow',
  'FullBleedHero',
  'BodyContent',
  'FeatureHero',
  'Header',
  'SplitHero',
  'SignupForm',
  'VideoHero',
  'ProductCarousel',
  'QuoteCallout',
];

/**
 * Returns an object such as { "componentKey": "headingTag" } to simplify the handling of component headings.
 * @param {HeadersContent | undefined} headers - Object containing headings configured from Sanity.
 * @param {any} items - Components rendered in current page.
 * @returns {Record<GqlComponentWithFilters['id'], ManageHeadingTag>} An object mapping headings (h1, h2, h3, h4) to component keys.
 */
export const mapSanityHeadingsToObject = (
  headers: HeadersContent | undefined,
  items: any
) => {
  const headerObj: Record<GqlComponentWithFilters['id'], ManageHeadingTag> = {};

  const addHeading = (
    heading: string,
    componentKey: string,
    componentRowKey?: string
  ) =>
    (headerObj[componentRowKey || componentKey] = heading as ManageHeadingTag);

  // only allow to add one if don't found in headers object from sanity
  const alreadySetOnce = { h1: false, h2: false, h3: false };

  headerKeys.forEach(objectKey => {
    const objectKeyHTag = objectKey.replace('Heading', '').replace('s', '');
    const currentHeader = headers?.[objectKey];
    const currentHeaderIsArray = Array.isArray(currentHeader);

    if (
      (!currentHeaderIsArray && currentHeader) ||
      (currentHeaderIsArray && currentHeader.length)
    ) {
      if (!currentHeaderIsArray) {
        const { componentKey, componentRowKey } = headers?.[objectKey];
        addHeading(objectKeyHTag, componentKey, componentRowKey);
      } else {
        currentHeader.forEach(({ componentKey, componentRowKey }) => {
          addHeading(objectKeyHTag, componentKey, componentRowKey);
        });
      }
    } else {
      items.forEach(({ component, id }) => {
        //TODO: check why component is coming null
        if (
          !alreadySetOnce[objectKeyHTag] &&
          WHITELIST_COMPONENTS.some(type => type === component?._type)
        ) {
          const componentKey =
            component?._type === 'FeaturesGrid'
              ? component.featureRows[0]._key
              : id;

          if (!headerObj[componentKey]) {
            addHeading(objectKeyHTag, componentKey);

            if (PRIMARY_HEADINGS.includes(objectKeyHTag)) {
              alreadySetOnce[objectKeyHTag] = true;
            }
          }
        }
      });
    }
  });

  return headerObj;
};
